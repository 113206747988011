<template>
  <!-- <h1>Admin</h1> -->
  <router-view />
</template>

<script>
export default {
  name: "Admin",
};
</script>
